import { createBrowserRouter } from "react-router-dom";

import {
  // Public routes start
  Root,
  Layout,
  Home,
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  EsewaPaymentSuccess,
  EsewaPaymentFail,
  KhaltiPayment,
  // KhaltiPaymentFail,
  Contact,
  Product,
  ProductsByCategory,
  ProductsBYBrand,
  ProductsGrid,
  SpecialProducts,
  SearchProducts,
  ShopProductsGrid,
  ProductsByShopTheLook,
  Page,
  FAQs,
  Blogs,
  Blog,
  About,
  NotFound,
  ErrorFallback,
  // Public routes end

  // User routes start
  RequireUser,
  UserCartCheckout,
  UserWishlist,
  UserDashboard,
  UserProfile,
  UserChangePassword,
  UserAddressBook,
  UserRewardCoin,
  UserOrders,
  UserReviews,
  // User routes end

  // Admin routes start
  RequireAdmin,
  AdminProfile,
  AdminChangePassword,
  AdminDashboard,
  AdminSliders,
  AdminCategories,
  AdminBrands,
  AdminColors,
  AdminDeliveryAddresses,
  AdminVouchers,
  AdminProducts,
  AdminShopTheLooks,
  AdminShopTheLookForm,
  AdminShopTheLookProducts,
  AdminReviews,
  AdminOrders,
  AdminProductForm,
  AdminUsersUsers,
  AdminUsersAdmins,
  AdminSiteSetting,
  AdminContactMessages,
  AdminHomepageAds,
  AdminEmailTemplates,
  AdminEmailTemplate,
  AdminSubscribers,
  AdminPages,
  AdminPage,
  AdminPagesSEO,
  AdminPageSEO,
  AdminFaqs,
  AdminBlogs,
  AdminBlogForm,
  // Admin routes end
} from "./imports";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    errorElement: <ErrorFallback />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "faq",
            element: <FAQs />,
          },
          {
            path: "reset-password/:token",
            element: <ResetPassword />,
          },
          {
            path: "verify-email/:token",
            element: <VerifyEmail />,
          },
          {
            path: "page/esewa-payment",
            element: <EsewaPaymentFail />,
          },
          {
            path: "page/esewa-payment-success",
            element: <EsewaPaymentSuccess />,
          },
          {
            path: "page/esewa-payment-fail",
            element: <EsewaPaymentFail />,
          },
          {
            path: "page/khalti-payment",
            element: <KhaltiPayment />,
          },
          // {
          //   path: "page/khalti-payment-fail",
          //   element: <KhaltiPaymentFail />,
          // },

          {
            path: "products/search/:keyword",
            element: <SearchProducts />,
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "contact",
            element: <Contact />,
          },
          {
            path: "category/:slug",
            element: <ProductsByCategory />,
          },
          {
            path: "products/:slug",
            element: <SpecialProducts />,
          },
          {
            path: "products/latest",
            element: <ProductsGrid />,
          },
          {
            path: "brand/:slug",
            element: <ProductsBYBrand />,
          },
          {
            path: "shop-the-look",
            element: <ShopProductsGrid />,
          },
          {
            path: "shop-the-look/:slug",
            element: <ProductsByShopTheLook />,
          },
          {
            path: "product/:slug",
            element: <Product />,
          },
          {
            path: "blog",
            element: <Blogs />,
          },
          {
            path: "blog/:slug",
            element: <Blog />,
          },
          {
            path: "privacy-policy",
            element: <Page slug="privacy-policy" />,
          },
          {
            path: "return-policy",
            element: <Page slug="return-policy" />,
          },
          {
            path: "terms-and-conditions",
            element: <Page slug="terms-and-conditions" />,
          },
        ],
      },
      {
        path: "user",
        element: <RequireUser />,
        children: [
          {
            path: "cart/checkout",
            element: <UserCartCheckout />,
          },
          {
            path: "profile",
            children: [
              {
                path: "",
                element: <UserDashboard />,
              },
              {
                path: "information",
                element: <UserProfile />,
              },
              {
                path: "wishlist",
                element: <UserWishlist />,
              },
              {
                path: "address",
                element: <UserAddressBook />,
              },
              {
                path: "reward-coin",
                element: <UserRewardCoin />,
              },
              {
                path: "orders",
                element: <UserOrders />,
              },
              {
                path: "reviews",
                element: <UserReviews />,
              },
              {
                path: "change-password",
                element: <UserChangePassword />,
              },
            ],
          },
        ],
      },
      {
        path: "admin",
        element: <RequireAdmin />,
        children: [
          {
            path: "",
            element: <AdminDashboard />,
          },
          {
            path: "profile",
            element: <AdminProfile />,
          },
          {
            path: "change-password",
            element: <AdminChangePassword />,
          },
          {
            path: "sliders",
            element: <AdminSliders />,
          },
          {
            path: "categories",
            element: <AdminCategories />,
          },
          {
            path: "brands",
            element: <AdminBrands />,
          },
          {
            path: "colors",
            element: <AdminColors />,
          },
          {
            path: "delivery-addresses",
            element: <AdminDeliveryAddresses />,
          },
          {
            path: "vouchers",
            element: <AdminVouchers />,
          },
          {
            path: "products",
            element: <AdminProducts />,
          },
          {
            path: "shop-the-look",
            element: <AdminShopTheLooks />,
          },
          {
            path: "shop-the-look/create",
            element: <AdminShopTheLookForm mode="create" />,
          },
          {
            path: "shop-the-look/:id/edit",
            element: <AdminShopTheLookForm mode="edit" />,
          },
          {
            path: "shop-the-look/:slug/products",
            element: <AdminShopTheLookProducts />,
          },
          {
            path: "reviews",
            element: <AdminReviews />,
          },
          {
            path: "orders",
            element: <AdminOrders />,
          },
          {
            path: "products/create",
            element: <AdminProductForm mode="create" />,
          },
          {
            path: "products/:id/edit",
            element: <AdminProductForm mode="edit" />,
          },
          {
            path: "users/users",
            element: <AdminUsersUsers />,
          },
          {
            path: "users/admins",
            element: <AdminUsersAdmins />,
          },
          {
            path: "misc/site-setting",
            element: <AdminSiteSetting />,
          },
          {
            path: "misc/contact-messages",
            element: <AdminContactMessages />,
          },
          {
            path: "misc/home-ads",
            element: <AdminHomepageAds />,
          },
          {
            path: "misc/email-templates",
            element: <AdminEmailTemplates />,
          },
          {
            path: "misc/email-templates/:slug",
            element: <AdminEmailTemplate />,
          },
          {
            path: "misc/subscribers",
            element: <AdminSubscribers />,
          },
          {
            path: "misc/pages",
            element: <AdminPages />,
          },
          {
            path: "misc/pages/:slug",
            element: <AdminPage />,
          },
          {
            path: "misc/pages-seo",
            element: <AdminPagesSEO />,
          },
          {
            path: "misc/pages/:slug",
            element: <AdminPageSEO />,
          },
          {
            path: "misc/faqs",
            element: <AdminFaqs />,
          },
          {
            path: "blogs",
            element: <AdminBlogs />,
          },
          {
            path: "blogs/create",
            element: <AdminBlogForm mode="create" />,
          },
          {
            path: "blogs/:id/edit",
            element: <AdminBlogForm mode="edit" />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
