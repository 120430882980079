import "./SuspenseLoading.scss";

export default function SuspenseLoading() {
  return (
    <div className="container flex h-screen w-screen flex-col items-center justify-center py-6 text-xl text-neutral-600 md:py-8">
      <div className="flex flex-col items-center justify-center">
        <div>
          <img src="/logo.png" className="h-12 object-contain" alt="Kaya-Lab" />
        </div>
        <span className="suspense_loading" />
      </div>
    </div>
  );
}
