import React from "react";

export const Root = React.lazy(() => import("components/root/Root"));
export const Layout = React.lazy(() => import("components/layout/Layout"));
export const Home = React.lazy(() => import("components/home/Home"));
export const Login = React.lazy(() => import("components/auth/login/Login"));
export const Register = React.lazy(
  () => import("components/auth/register/Register")
);
export const ForgotPassword = React.lazy(
  () => import("components/auth/forgotPassword/ForgotPassword")
);
export const ResetPassword = React.lazy(
  () => import("components/auth/resetPassword/ResetPassword")
);
export const VerifyEmail = React.lazy(
  () => import("components/auth/verifyEmail/VerifyEmail")
);
export const EsewaPaymentSuccess = React.lazy(
  () => import("components/pages/EsewaPaymentSuccess")
);
export const EsewaPaymentFail = React.lazy(
  () => import("components/pages/EsewaPaymentFail")
);

export const KhaltiPayment = React.lazy(
  () => import("components/pages/khaltiPayment")
);

export const KhaltiPaymentFail = React.lazy(
  () => import("components/pages/khaltiPaymentFail")
);
export const Contact = React.lazy(
  () => import("components/pages/contact/Contact")
);
export const Product = React.lazy(
  () => import("components/shop/product/Product")
);
export const SearchProducts = React.lazy(
  () => import("components/shop/searchProducts/SearchProducts")
);
export const ShopProductsGrid = React.lazy(
  () => import("components/shop/shopTheLook/ShopProductsGrid")
);
export const ProductsByShopTheLook = React.lazy(
  () => import("components/shop/shopTheLook/ProductsByShopTheLook")
);
export const ProductsByCategory = React.lazy(
  () => import("components/shop/productsByCategory/ProductsByCategory")
);
export const ProductsBYBrand = React.lazy(
  () => import("components/shop/brand/Brand")
);
export const SpecialProducts = React.lazy(
  () => import("components/shop/productsByCategory/SpecialProducts")
);
export const ProductsGrid = React.lazy(
  () => import("components/shop/productsGrid/ProductsByGrid")
);
export const About = React.lazy(() => import("components/pages/about/About"));
export const Blogs = React.lazy(() => import("components/misc/blogs/Blogs"));
export const Blog = React.lazy(() => import("components/misc/blogs/Blog"));
export const FAQs = React.lazy(() => import("components/misc/faq/FAQs"));
export const Page = React.lazy(() => import("components/misc/page/Page"));
export const NotFound = React.lazy(
  () => import("components/pages/notFound/NotFound")
);
export const ErrorFallback = React.lazy(
  () => import("components/errorFallback/ErrorFallback")
);

// User routes start
export const RequireUser = React.lazy(
  () => import("router/requireUser/RequireUser")
);
export const UserCartCheckout = React.lazy(
  () => import("components/user/cart/CartCheckout")
);
export const UserWishlist = React.lazy(
  () => import("components/user/wishlist/Wishlist")
);
export const UserDashboard = React.lazy(
  () => import("components/user/dashboard/Dashboard")
);
export const UserProfile = React.lazy(
  () => import("components/user/dashboard/profile/Profile")
);
export const UserAddressBook = React.lazy(
  () => import("components/user/dashboard/addressBook/AddressBook")
);
export const UserRewardCoin = React.lazy(
  () => import("components/user/dashboard/rewardCoin/RewardCoin")
);
export const UserOrders = React.lazy(
  () => import("components/user/dashboard/orders/Orders")
);
export const UserReviews = React.lazy(
  () => import("components/user/dashboard/reviews/Reviews")
);
export const UserChangePassword = React.lazy(
  () => import("components/user/dashboard/changePassword/ChangePassword")
);
// User routes end

// Admin routes start
export const RequireAdmin = React.lazy(
  () => import("router/requireAdmin/RequireAdmin")
);
export const AdminProfile = React.lazy(
  () => import("components/admin/profile/Profile")
);
export const AdminChangePassword = React.lazy(
  () => import("components/admin/changePassword/ChangePassword")
);
export const AdminDashboard = React.lazy(
  () => import("components/admin/dashboard/Dashboard")
);
export const AdminSliders = React.lazy(
  () => import("components/admin/sliders/Sliders")
);
export const AdminCategories = React.lazy(
  () => import("components/admin/categories/Categories")
);
export const AdminBrands = React.lazy(
  () => import("components/admin/brands/Brands")
);
export const AdminColors = React.lazy(
  () => import("components/admin/colors/Colors")
);
export const AdminDeliveryAddresses = React.lazy(
  () => import("components/admin/deliveryAddresses/DeliveryAddresses")
);
export const AdminVouchers = React.lazy(
  () => import("components/admin/vouchers/Vouchers")
);
export const AdminProducts = React.lazy(
  () => import("components/admin/products/Products")
);
export const AdminShopTheLooks = React.lazy(
  () => import("components/admin/shopTheLooks/ShopTheLooks")
);
export const AdminShopTheLookForm = React.lazy(
  () => import("components/admin/shopTheLooks/form/ShopTheLookForm")
);
export const AdminShopTheLookProducts = React.lazy(
  () => import("components/admin/shopTheLooks/ShopTheLookProducts")
);
export const AdminReviews = React.lazy(
  () => import("components/admin/reviews/Reviews")
);
export const AdminOrders = React.lazy(
  () => import("components/admin/orders/Orders")
);
export const AdminProductForm = React.lazy(
  () => import("components/admin/products/form/ProductForm")
);
export const AdminUsersUsers = React.lazy(
  () => import("components/admin/users/users/Users")
);
export const AdminUsersAdmins = React.lazy(
  () => import("components/admin/users/admins/Admins")
);
export const AdminSiteSetting = React.lazy(
  () => import("components/admin/misc/siteSetting/SiteSetting")
);
export const AdminContactMessages = React.lazy(
  () => import("components/admin/misc/contactMessages/ContactMessages")
);
export const AdminHomepageAds = React.lazy(
  () => import("components/admin/misc/homeAds/HomeAds")
);
export const AdminEmailTemplates = React.lazy(
  () => import("components/admin/misc/emailTemplates/EmailTemplates")
);
export const AdminEmailTemplate = React.lazy(
  () => import("components/admin/misc/emailTemplates/form/EmailTemplateForm")
);
export const AdminSubscribers = React.lazy(
  () => import("components/admin/misc/subscribers/Subscribers")
);
export const AdminPages = React.lazy(
  () => import("components/admin/misc/pages/Pages")
);
export const AdminPage = React.lazy(
  () => import("components/admin/misc/pages/form/PageForm")
);
export const AdminPagesSEO = React.lazy(
  () => import("components/admin/misc/seoPage/PagesSeo")
);
export const AdminPageSEO = React.lazy(
  () => import("components/admin/misc/seoPage/PagesSeo")
);
export const AdminFaqs = React.lazy(
  () => import("components/admin/misc/faqs/Faqs")
);
export const AdminBlogs = React.lazy(
  () => import("components/admin/blogs/Blogs")
);
export const AdminBlogForm = React.lazy(
  () => import("components/admin/blogs/form/BlogForm")
);
// Admin routes end
